<template>
  <div>
    <j-header></j-header>
    <div class="project">
      <div class="project-introduce">
        <div class="project-introduce-con">
          <j-list :listData="productData">
            <div slot="listText" class="product-p-w">
              <p class="product-jy">智慧监狱</p>
              <p>
                久冠【亲情可视电话】监狱视频探视系统（具有商标权、软件著作权、专利权），专为监狱服刑人员家属远程视频会见而设计。是一款便民利民、安全实用的视频通信产品，为监狱服刑人员家属提供远程视频会见服务。
              </p>
              <p>
                该系统由家属移动探视端、服刑人员探视端、监狱监控端、监狱审核端、监狱
                管理员端、监狱管理员端组成。有了这套系统，家属在家、办公室或外地出差，
                都可实现与异地监狱服刑改造的亲人远程视频会见预约和远程视频会见。整个探
                视过程全程监控，可喊话、可暂停、设置延时、自动录音录像、自动合成存储、
                随时调取录像回放、人脸识别报警，自动启动应急预案，自动警告、报警、强
                制结束探视。
              </p>
              <p>
                该系统采用互联网—专网安全隔离技术，实现互联网—专网的数据安全转换，
                保证数据存储、数据传输的安全。该视频探视系统广泛应用于监狱、戒毒所、
                拘留所、看守所等监管场所。
              </p>
              <img class="product-step1" src="../../assets/product-step1.png" alt="">
            </div>
            <div slot="listImg">
              <img class="product-img" src="../../assets/product-img.png" alt="">
            </div>
          </j-list>
        </div>
      </div>
      <div class="project-jds">
        <div class="project-jds-con">
          <j-list :listData="jdsData">
            <div slot="listText" class="product-p-w">
              <p class="product-jy">阳光戒毒所</p>
              <p>
                亲视通APP（具有商标权、软件著作权、专利权），专为戒毒学员家属远程视频会见而设计。是一款便民利民、安全实用的视频通信产品，为戒毒学员家属提供远程视频会见服务。
              </p>
              <img class="product-step2" src="../../assets/product-step2.png" alt="">
            </div>
            <div slot="listImg">
              <img class="jds-img" src="../../assets/jds.png" alt="">
            </div>
          </j-list>
        </div>
      </div>
      <div class="project-jail">
        <div class="project-jail-con">
          <j-list :listData="jailData">
            <div slot="listText" class="product-p-w">
              <p class="product-jy">便民看守所---公检法、律师远程提审系统</p>
              <p>
                该系统采用互联网—专网安全隔离技术，实现互联网—专网的数据安全转换，
                保证数据存储、数据传输的安全。该视频探视系统广泛应用于监狱、戒毒所、
                拘留所、看守所等监管场所。
              </p>
              <p>
                免去了办案人员去监狱提审犯人的往返路程，节省了办案时间，提高了工作
                效率。对于秉公办案、秉公执法、案件安全有了保障。该系统从会见预约、
                审核、登记，到在押人员提押、还押，再到文书交接、材料备案，全流程工作
                数据、视频资料真实无误上传并储存留底，一旦发生安全或违规问题，证据齐
                备、倒查有据。
              </p>
              <img class="product-step3" src="../../assets/product-step3.png" alt="">
            </div>
            <div slot="listImg">
              <img class="jail-img" src="../../assets/jail.png" alt="">
            </div>
          </j-list>
        </div>
      </div>
      <div class="project-jxt">
        <div class="project-jxt-con">
          <j-list :listData="jxtData">
            <div slot="listText" class="product-p-w">
              <p class="product-jy">军讯通---入伍士兵远程音视频通话系统</p>
              <p>
                久冠军讯通通讯系统（具有商标权、软件著作权、专利权），专为入伍士兵
                和其亲朋好友远程视频会见而设计。是一款便民利民、安全实用的音视频通
                信产品。
              </p>
              <p>
                该系统使用自动身份验证，保证了使用者的信息真实和安全，解决了入伍
                新兵同家人联系难、联系方式单一的问题，提高了新入伍士兵的训练效率，
                缓解了新兵的思乡情绪，使新兵入伍之后的高效训练有了保障。
              </p>
              <img class="product-step4" src="../../assets/product-step4.png" alt="">
            </div>
            <div slot="listImg">
              <img class="jxt-img" src="../../assets/jxt.png" alt="">
            </div>
          </j-list>
        </div>
      </div>
    </div>
    <j-foot></j-foot>
  </div>
</template>

<script>
import JList from "@/components/JList";
import pBg from "@/assets/product-name-bg.png";
export default {
  components: {
    JList
  },
  data() {
    return {
      productData: {
        name: '产品介绍',
        nameBg: pBg,
        type: 'tRight'
      },
      jdsData: {
        type: 'tLeft'
      },
      jailData: {
        type: 'tRight'
      },
      jxtData: {
        type: 'tLeft'
      }
    }
  }
}
</script>

<style scoped>
.project-introduce{
  height: 1083px;
  width: 100%;
}
.project-introduce-con{
  width: 1200px;
  margin: 0 auto;
  margin-top: 146px;
}
.product-img{
  width: 578px;
  height: 427px;
  margin-top: 100px;
}
.product-p-w{
  width: 567px;
  /*margin-top: 114px;*/
}
.product-jy{
  height: 33px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 33px;
  color: #393939;
  opacity: 1;
}
.product-step1{
  width: 138px;
  height: 84px;
  float: right;
  margin-top: 18px;
}
.project-jds{
  width: 100%;
  height: 1080px;
  background: url("../../assets/jds-bg.png") no-repeat center center;
}
.project-jds-con{
  width: 1200px;
  margin: 0 auto;
  padding-top: 327px;
}
.product-step2{
  width: 138px;
  height: 84px;
  margin-top: 51px;
  float: left;
}
.jds-img{
  width: 578px;
  height: 427px;
}
.project-jail{
  width: 100%;
  height: 1080px;
}
.project-jail-con{
  width: 1200px;
  margin: 0 auto;
  padding-top: 303px;
}
.product-step3{
  float: right;
  width: 138px;
  height: 84px;
}
.jail-img{
  width: 578px;
  height: 427px;
}
.project-jxt{
  width: 100%;
  height: 1080px;
}
.project-jxt-con{
  width: 1200px;
  margin: 0 auto;
  padding-top: 303px;
}
.jxt-img{
  width: 578px;
  height: 427px;
}
.product-step4{
  width: 138px;
  height: 84px;
  float: right;
}
</style>
